import request from "../../utils/request";


// 查询列表
export function listDocument(params) {
  return request({
    url: '/api/solar/documents/',
    method: 'get',
    params: params
  })
}


// 查询单个详情
export function getDocument(documentId) {
  return request({
    url: '/api/solar/documents/' + documentId + '/',
    method: 'get',
  })
}


// 新增
export function addDocument(data) {
  return request({
    url: '/api/solar/documents/',
    method: 'post',
    data: data,
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  })
}


// 修改(全部修改)
export function updateDocumentPut(documentId, data) {
  return request({
    url: '/api/solar/documents/' + documentId + '/',
    method: 'put',
    data: data
  })
}


// 修改(单个字段修改)
export function updateDocumentPatch(documentId, data) {
  return request({
    url: '/api/solar/documents/' + documentId + '/',
    method: 'patch',
    data: data
  })
}


// 删除
export function delDocument(documentId) {
  return request({
    url: '/api/solar/documents/' + documentId + '/',
    method: 'delete'
  })
}
