<!--@Time : 2021/11/16 10:54-->
<!--@Author : WangHaoRan-->
<!--@Annotation :-->
<template>
	<div>
		<Header></Header>
		<v-row class="d-flex justify-end">
			<v-col cols="3" md="3" lg="3">
				<v-select
					dense
					v-model="fileCategory"
					:items="fileCategoryList"
					return-object
					label="Category"
					clearable
					persistent-hint
				>
				</v-select>
			</v-col>
		</v-row>

		<v-row>
			<v-col cols="12" md="12" lg="12">
				<v-data-table
					:headers="headers"
					:items="desserts"
					:loading="loading"
					:options.sync="options"
					:server-items-length="totalDesserts"
					class="elevation-0"
				>
					<template v-slot:item.action="{ item }">
						<a :href="item.filepath" download target="_blank">
							<v-icon color="success" small class="mr-2"
								>mdi-download</v-icon
							>
						</a>
						<v-icon
							color="error"
							small
							@click="delData(item)"
							:disabled="$store.getters.username !== 'admin'"
							v-if="$store.getters.username === 'admin'"
						>
							mdi-delete
						</v-icon>
					</template>
				</v-data-table>

				<v-btn
					v-if="isShow"
					outlined
					small
					color="primary"
					@click="dialog = true"
					>Upload a File</v-btn
				>
			</v-col>
		</v-row>

		<v-dialog v-model="dialog" max-width="600px">
			<v-card>
				<v-card-title>Upload a File</v-card-title>
				<v-card-text>
					<v-select
						v-model="fileCategory_1"
						:items="fileCategoryList"
						return-object
						label=""
						hint="Category"
						persistent-hint
					>
					</v-select>
					<v-file-input
						v-model="file"
						show-size
						counter
						multiple
						label="File input"
					></v-file-input>
				</v-card-text>
				<v-divider></v-divider>
				<v-card-actions>
					<v-spacer></v-spacer>
					<v-btn color="primary" text small @click="clearFile"
						>Cancel</v-btn
					>
					<v-btn color="primary" text small @click="logFile"
						>Upload</v-btn
					>
				</v-card-actions>
			</v-card>
		</v-dialog>

		<v-dialog v-model="dialogDelete" max-width="600px">
			<v-card>
				<v-card-title>Confirm delete?</v-card-title>
				<v-divider></v-divider>
				<v-card-actions>
					<v-spacer></v-spacer>
					<v-btn
						color="primary"
						text
						small
						@click="dialogDelete = false"
						>No</v-btn
					>
					<v-btn color="primary" text small @click="deleteItemConfirm"
						>Yes</v-btn
					>
				</v-card-actions>
			</v-card>
		</v-dialog>
	</div>
</template>

<script>
import Header from "@/components/Header";
import { Message } from "element-ui";
import {
	addDocument,
	delDocument,
	listDocument,
} from "../../api/solar/document";
export default {
	name: "Documents",
	components: { Header },
	data() {
		return {
			isShow: this.$store.getters.groups === 1,
			fileCategoryList: [
				{ text: "Renderings", value: 1 },
				{ text: "Photos", value: 2 },
				{ text: "Technical Files", value: 3 },
			],
			fileCategory: null,
			fileCategory_1: {},

			file: [],
			dialog: false,
			dialogDelete: false,
			delId: null,
			headers: [
				{ text: "#", value: "no", width: 100 },
				{ text: "Category", value: "category" },
				{ text: "File Name", value: "name" },
				{ text: "Date Modified", value: "date_modified" },
				{ text: "Size", value: "size" },
				{ text: "Kind", value: "kind" },
				{ text: "Action", value: "action", width: 220 },
			],
			desserts: [],
			loading: false,
			totalDesserts: 0,
			options: {
				page: 1,
				itemsPerPage: 10,
			},
		};
	},
	created() {
    if (this.$store.getters.team){
      this.getDataFromApi()
    }
  },
  beforeDestroy() {
    this.$message.close()
  },
	watch: {
		"$store.getters.team": {
			handler(newValue) {
        this.desserts = [];
        if (newValue !== null) {
          this.getDataFromApi();
        }
      },
    },
		options: {
			handler() {
				this.getDataFromApi();
			},
			deep: true,
		},
		fileCategory: {
			handler() {
				this.getDataFromApi();
			},
		},
		$route(route) {
			console.log(route);
		},
	},
	methods: {
		delData(item) {
			this.dialogDelete = true;
			this.delId = item;
		},

		deleteItemConfirm() {
			this.desserts.splice(this.delId.no - 1, 1);
			this.totalDesserts -= 1;
			this.dialogDelete = false;
			delDocument(this.delId.id).then(() => {
				Message({
					message: "Delete Successful",
					type: "success",
				});
			});
		},

		clearFile() {
			this.file = [];
			this.dialog = false;
			this.fileCategory_1 = {};
		},

		logFile() {
			const FormData = new window.FormData();
			FormData.append("filename", this.file[0].name);
			FormData.append("filepath", this.file[0]);
			FormData.append("category", this.fileCategory_1.value);
			this.clearFile();
			addDocument(FormData).then(() => {
				this.getDataFromApi();
				Message({
					message: "Upload Successful",
					type: "success",
				});
			});
		},

		getDataFromApi() {
			this.loading = true;
			const params = {};
			if (this.fileCategory !== null) {
				params["category"] = this.fileCategory.value;
			}
			if (this.$store.getters.team !== null) {
				params["team"] = this.$store.getters.team.id;
			} else {
				this.loading = false;
				return;
			}
			listDocument(params).then((res) => {
				this.desserts = [];
				this.totalDesserts = res["count"];
				res["results"].forEach((item, index) => {
					this.desserts.push({
						no: index + 1,
						id: item["id"],
						category: item["category"],
						name: item["filename"],
						date_modified: item["create_time"],
						size: item["filesize"],
						kind: item["filepath"].split(".").reverse()[0],
						filepath:
							process.env.VUE_APP_BACKEND_URL + item["filepath"],
					});
				});
				this.loading = false;
			});
		},

		pageSize(pagesize) {
			if (pagesize === -1) {
				return 1000;
			} else return pagesize;
		},
	},
};
</script>

<style scoped>
</style>